import React from 'react';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { Box, NavigationMenu } from 'components';
import logo from 'images/logo-black.svg';
import logoSmall from 'images/logo-small.svg';

import { useMenu } from './Menu/useMenu';

import { styled, useNavigate, useSelector } from 'third-party';

import { dashboardUrl, organizationListUrl } from 'constants/url';

import { appSelectors } from 'selectors';

const OrganizationText = styled.p`
  font-style: normal;
  line-height: 2rem;
  font-weight: 500;
  padding-left: 0.5rem;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Navigation = () => {
  const menu = useMenu();
  const navigate = useNavigate();
  const selected = useSelector(appSelectors.selectedOrganization);
  const isNavigationCollapsed = useSelector(appSelectors.isNavigationCollapsed);

  const onLogoClick = () => {
    navigate(dashboardUrl());
  };

  const onOrganizationClick = () => {
    navigate(organizationListUrl());
  };

  return (
    <>
      <Box
        margin="2rem 2rem 1rem 1rem"
        alignContent="center"
        justifyContent="center"
        display="flex"
        sx={{ cursor: 'pointer' }}
        onClick={onLogoClick}
      >
        {isNavigationCollapsed ? (
          <img src={logoSmall} height="33px" style={{ paddingLeft: '1rem' }} />
        ) : (
          <img src={logo} height="33px" />
        )}
      </Box>
      {selected && (
        <Box
          display="flex"
          onClick={onOrganizationClick}
          sx={{ cursor: 'pointer' }}
          paddingBottom="0.3rem"
          paddingLeft="1.5rem"
          alignItems="center"
        >
          <ArrowBackOutlinedIcon
            color="primary"
            fontSize="small"
            style={{ padding: isNavigationCollapsed ? '0.5rem' : '0rem' }}
          />
          {!isNavigationCollapsed && (
            <OrganizationText>{selected.organization.name}</OrganizationText>
          )}
        </Box>
      )}
      <Box display="flex" overflow="auto" flexDirection="column" flexGrow="1">
        <Box>
          {menu.map((it, index) => (
            <NavigationMenu
              key={index}
              menu={it}
              isCollapsed={isNavigationCollapsed}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
