import React from 'react';

import { Box, List, ListSubheader } from '@mui/material';
import { NavigationLink } from 'components';

import { MenuType } from 'types/app';

type Props = {
  menu: MenuType;
  isCollapsed: boolean;
};

export const NavigationMenu: React.FC<Props> = ({ menu, isCollapsed }) => {
  return (
    <Box margin="0rem 1rem 0rem 1rem">
      <List
        subheader={
          <ListSubheader>{!isCollapsed && menu.subHeader}</ListSubheader>
        }
      >
        {menu.items.map((it, index) => (
          <NavigationLink key={index} item={it} isCollapsed={isCollapsed} />
        ))}
      </List>
    </Box>
  );
};
