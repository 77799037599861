import React from 'react';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import {
  bankListUrl,
  memberListUrl,
  organizationDashboardUrl,
  payeeListUrl,
  paymentListUrl,
  settingsUrl,
  userActivityListUrl,
} from 'constants/url';

import { MenuType } from 'types/app';

export const OrganizationUserMenu = (organizationId: string): MenuType[] => [
  {
    subHeader: 'Activity',
    items: [
      {
        url: () => organizationDashboardUrl(organizationId),
        text: 'Dashboard',
        icon: <BarChartRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => paymentListUrl(organizationId),
        text: 'Payments',
        icon: <CreditCardRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => payeeListUrl(organizationId),
        text: 'Payees',
        icon: <PaymentsOutlinedIcon color="primary" fontSize="small" />,
      },
    ],
  },
];

export const OrganizationAdminMenu = (organizationId: string): MenuType[] => [
  {
    subHeader: 'Organization',
    items: [
      {
        url: () => bankListUrl(organizationId),
        text: 'Banks',
        icon: <AccountBalanceOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => memberListUrl(organizationId),
        text: 'Members',
        icon: <GroupOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => userActivityListUrl(organizationId),
        text: 'User activity',
        icon: <ManageSearchRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => settingsUrl(organizationId),
        text: 'Settings',
        icon: <SettingsOutlinedIcon color="primary" fontSize="small" />,
      },
    ],
  },
];
