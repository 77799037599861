import React, { useEffect, useRef } from 'react';

import { MenuItem, SelectChangeEvent } from 'components';
import { Select } from 'components';

import { useTranslation } from 'third-party';

import { useSettings } from 'modules/setting';

import { APISettingName } from 'types/api';

const DEFAULT_TEMPLATE = APISettingName.NewECheckReceiveEmail;

export const TemplateSelector = () => {
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const { loadEmailTemplate, setSelectedTemplate } = useSettings();

  useEffect(() => {
    if (ref.current != null) {
      loadEmailTemplate(DEFAULT_TEMPLATE);
      ref.current.value = DEFAULT_TEMPLATE;
    }
  }, [ref, loadEmailTemplate]);

  const handleChange = (event: SelectChangeEvent) => {
    const template = event.target.value as APISettingName;

    loadEmailTemplate(template);
    setSelectedTemplate(template);
    ref.current.value = template;
  };

  return (
    <Select
      inputRef={ref}
      size="small"
      variant="standard"
      fullWidth
      defaultValue={DEFAULT_TEMPLATE}
      labelId="demo-select-small-label"
      id="demo-select-small"
      displayEmpty
      sx={{
        minWidth: '10rem',
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
      }}
      onChange={handleChange}
    >
      <MenuItem value={APISettingName.NewECheckReceiveEmail}>
        {t('emailDesigner.template.NewECheckReceiveEmail')}
      </MenuItem>
      <MenuItem value={APISettingName.AddedToOrganizationEmail}>
        {t('emailDesigner.template.AddedToOrganizationEmail')}
      </MenuItem>
    </Select>
  );
};
