import React from 'react';

import {
  AppContainer,
  BanksList,
  CheckDesigner,
  Dashboard,
  EmptyStub,
  Login,
  LoginView,
  MainContainer,
  MainView,
  NotFoundPage,
  OrganizationList,
  PayeeList,
  PaymentList,
  ResetPassword,
  Settings,
  UserForgotPassword,
  UserList,
  UserPayeeList,
  UserRegister,
} from 'features';

import { Route } from 'third-party';

import {
  appUrl,
  bankCheckListUrl,
  bankListUrl,
  dashboardUrl,
  forgotPasswordUrl,
  loginUrl,
  memberListUrl,
  notFoundUrl,
  organizationDashboardUrl,
  organizationListUrl,
  payeeListUrl,
  paymentListUrl,
  registerUrl,
  resetPasswordUrl,
  resetUserPasswordUrl,
  settingsUrl,
  userActivityListUrl,
  userFormUrl,
  userPayeeListUrl,
  userPaymentListUrl,
} from 'constants/url';

export const useRoutes = () => {
  return (
    <Route path={appUrl()} element={<MainContainer />}>
      {/* anonyms  routes */}
      <Route path={resetPasswordUrl()} element={<LoginView />}>
        <Route
          path={resetUserPasswordUrl(':email', ':token')}
          element={<ResetPassword />}
        />
      </Route>

      <Route path={appUrl()} element={<AppContainer />}>
        <Route path={userFormUrl()} element={<LoginView />}>
          <Route path={registerUrl()} element={<UserRegister />} />
          <Route path={forgotPasswordUrl()} element={<UserForgotPassword />} />
          <Route path={loginUrl()} element={<Login />} />
        </Route>
        <Route path={appUrl()} element={<MainView />}>
          {/* user routes */}
          <Route path={dashboardUrl()} element={<Dashboard />} />
          <Route path={userPaymentListUrl()} element={<PaymentList />} />
          <Route path={organizationListUrl()} element={<OrganizationList />} />
          <Route path={userPayeeListUrl()} element={<UserPayeeList />} />

          {/* organization routes */}
          <Route
            path={organizationDashboardUrl(':organizationId')}
            element={<Dashboard />}
          />
          <Route
            path={paymentListUrl(':organizationId')}
            element={<PaymentList />}
          />
          <Route
            path={payeeListUrl(':organizationId')}
            element={<PayeeList />}
          />
          <Route
            path={bankListUrl(':organizationId')}
            element={<BanksList />}
          />

          <Route
            path={bankCheckListUrl(':organizationId', ':bankId')}
            element={<CheckDesigner />}
          />

          <Route
            path={memberListUrl(':organizationId')}
            element={<UserList />}
          />
          <Route
            path={userActivityListUrl(':organizationId')}
            element={<EmptyStub />}
          />
          <Route path={settingsUrl(':organizationId')} element={<Settings />} />
        </Route>
      </Route>

      <Route path={notFoundUrl()} element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  );
};
