import React from 'react';

import { Container as BaseContainer } from '@usewaypoint/block-container';
import { ContainerProps as BaseContainerProps } from '@usewaypoint/block-container';

import { useCurrentBlockId } from './EditorBlock';
import { EditorChildrenIds } from './Wrappers/EditorChildrenIds';

import { useSelector } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

interface Column {
  childrenIds: string[];
}

export type ContainerProps = {
  style: BaseContainerProps['style'];
  children: BaseContainerProps['children'];
  props?: Column;
};

export default function ContainerEditor({ style, props }: ContainerProps) {
  const childrenIds = props?.childrenIds ?? [];

  const document = useSelector(settingSelectors.emailTemplate);
  const currentBlockId = useCurrentBlockId();
  const { setEmailTemplate, setSelectedTemplateBlockId } = useSettings();

  return (
    <BaseContainer style={style}>
      <EditorChildrenIds
        childrenIds={childrenIds}
        onChange={({ block, blockId, childrenIds }) => {
          setEmailTemplate({
            [blockId]: block,
            [currentBlockId]: {
              type: 'Container',
              data: {
                ...document[currentBlockId].data,
                props: { childrenIds: childrenIds },
              },
            },
          });
          setSelectedTemplateBlockId(blockId);
        }}
      />
    </BaseContainer>
  );
}
