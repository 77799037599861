import React, { createContext, useContext } from 'react';

import { renderers } from './Renders';

import { useSelector } from 'third-party';

import { settingSelectors } from 'selectors';

const EditorBlockContext = createContext<string | null>(null);
export const useCurrentBlockId = () => useContext(EditorBlockContext)!;

type Props = {
  id: string;
};

export const EditorBlock: React.FC<Props> = ({ id }) => {
  const document = useSelector(settingSelectors.emailTemplate);

  const block = document[id];
  if (!block) {
    throw new Error('Could not find block');
  }

  const Component = renderers[block.type];
  return (
    <EditorBlockContext.Provider value={id}>
      <Component {...block.data} />
    </EditorBlockContext.Provider>
  );
};
