import React from 'react';

import { useCurrentBlockId } from './EditorBlock';
import { EditorChildrenIds } from './Wrappers/EditorChildrenIds';

import { useSelector } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

function getFontFamily(fontFamily: EmailLayoutProps['fontFamily']) {
  const f = fontFamily ?? 'MODERN_SANS';
  switch (f) {
    case 'MODERN_SANS':
      return '"Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif';
    case 'BOOK_SANS':
      return 'Optima, Candara, "Noto Sans", source-sans-pro, sans-serif';
    case 'ORGANIC_SANS':
      return 'Seravek, "Gill Sans Nova", Ubuntu, Calibri, "DejaVu Sans", source-sans-pro, sans-serif';
    case 'GEOMETRIC_SANS':
      return 'Avenir, "Avenir Next LT Pro", Montserrat, Corbel, "URW Gothic", source-sans-pro, sans-serif';
    case 'HEAVY_SANS':
      return 'Bahnschrift, "DIN Alternate", "Franklin Gothic Medium", "Nimbus Sans Narrow", sans-serif-condensed, sans-serif';
    case 'ROUNDED_SANS':
      return 'ui-rounded, "Hiragino Maru Gothic ProN", Quicksand, Comfortaa, Manjari, "Arial Rounded MT Bold", Calibri, source-sans-pro, sans-serif';
    case 'MODERN_SERIF':
      return 'Charter, "Bitstream Charter", "Sitka Text", Cambria, serif';
    case 'BOOK_SERIF':
      return '"Iowan Old Style", "Palatino Linotype", "URW Palladio L", P052, serif';
    case 'MONOSPACE':
      return '"Nimbus Mono PS", "Courier New", "Cutive Mono", monospace';
  }
}

type FontFamily =
  | 'MODERN_SANS'
  | 'BOOK_SANS'
  | 'ORGANIC_SANS'
  | 'GEOMETRIC_SANS'
  | 'HEAVY_SANS'
  | 'ROUNDED_SANS'
  | 'MODERN_SERIF'
  | 'BOOK_SERIF'
  | 'MONOSPACE'
  | null
  | undefined;

type Color = string | null | undefined;

export type EmailLayoutProps = {
  backdropColor?: Color;
  borderColor?: Color;
  borderRadius?: number | null;
  canvasColor?: Color;
  textColor?: Color;
  fontFamily?: FontFamily;
  childrenIds?: string[] | null;
};

export default function EmailLayoutEditor(props: EmailLayoutProps) {
  const childrenIds = props.childrenIds ?? [];
  const document = useSelector(settingSelectors.emailTemplate);
  const currentBlockId = useCurrentBlockId();
  const { setEmailTemplate, setSelectedTemplateBlockId } = useSettings();

  return (
    <div
      onClick={() => {
        setSelectedTemplateBlockId(null);
      }}
      style={{
        backgroundColor: props.backdropColor ?? '#F5F5F5',
        color: props.textColor ?? '#262626',
        fontFamily: getFontFamily(props.fontFamily),
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0.15008px',
        lineHeight: '1.5',
        margin: '0',
        padding: '32px 0',
        width: '100%',
        minHeight: '100%',
      }}
    >
      <table
        align="center"
        width="100%"
        style={{
          margin: '0 auto',
          maxWidth: '600px',
          backgroundColor: props.canvasColor ?? '#FFFFFF',
          borderRadius: props.borderRadius ?? undefined,
          border: (() => {
            const v = props.borderColor;
            if (!v) {
              return undefined;
            }
            return `1px solid ${v}`;
          })(),
        }}
        role="presentation"
        cellSpacing="0"
        cellPadding="0"
        border={0}
      >
        <tbody>
          <tr style={{ width: '100%' }}>
            <td>
              <EditorChildrenIds
                childrenIds={childrenIds}
                onChange={({ block, blockId, childrenIds }) => {
                  setEmailTemplate({
                    [blockId]: block,
                    [currentBlockId]: {
                      type: 'EmailLayout',
                      data: {
                        ...document[currentBlockId].data,
                        childrenIds: childrenIds,
                      },
                    },
                  });
                  setSelectedTemplateBlockId(blockId);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
