import React from 'react';

import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from 'components';

import { useLocation } from 'third-party';

import { NavigationItems } from 'types/app';

type Props = {
  item: NavigationItems;
  isCollapsed: boolean;
};

export const NavigationLink: React.FC<Props> = ({ item, isCollapsed }) => {
  const { pathname } = useLocation();

  return (
    <Link href={item.url?.() || '#'} underline="none">
      <ListItem disablePadding>
        <ListItemButton selected={pathname == item.url?.()}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          {!isCollapsed && (
            <ListItemText
              primary={item.text}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
