import React from 'react';

import { ButtonProps, ButtonPropsDefaults } from '@usewaypoint/block-button';
import { ToggleButton } from 'components';

import {
  BaseSidebarPanel,
  ColorInput,
  MultiStylePropertyPanel,
  RadioGroupInput,
  TextInput,
} from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: ButtonProps;
  setData: (data: ButtonProps) => void;
};

export const ButtonSidebarPanel: React.FC<Props> = ({ data, setData }) => {
  const updateData = (data: any) => {
    setData(data);
  };

  const { t } = useTranslation();

  const text = data.props?.text ?? ButtonPropsDefaults.text;
  const url = data.props?.url ?? ButtonPropsDefaults.url;
  const fullWidth = data.props?.fullWidth ?? ButtonPropsDefaults.fullWidth;
  const size = data.props?.size ?? ButtonPropsDefaults.size;
  const buttonStyle =
    data.props?.buttonStyle ?? ButtonPropsDefaults.buttonStyle;
  const buttonTextColor =
    data.props?.buttonTextColor ?? ButtonPropsDefaults.buttonTextColor;
  const buttonBackgroundColor =
    data.props?.buttonBackgroundColor ??
    ButtonPropsDefaults.buttonBackgroundColor;

  return (
    <BaseSidebarPanel title={t('emailDesigner.panel.button')}>
      <TextInput
        label={t('emailDesigner.panel.button.text')}
        defaultValue={text}
        onChange={text =>
          updateData({ ...data, props: { ...data.props, text } })
        }
      />
      <TextInput
        label={t('emailDesigner.panel.button.url')}
        defaultValue={url}
        onChange={url => updateData({ ...data, props: { ...data.props, url } })}
      />
      <RadioGroupInput
        label={t('emailDesigner.panel.button.width')}
        defaultValue={fullWidth ? 'FULL_WIDTH' : 'AUTO'}
        onChange={v =>
          updateData({
            ...data,
            props: { ...data.props, fullWidth: v === 'FULL_WIDTH' },
          })
        }
      >
        <ToggleButton value="FULL_WIDTH">
          {t('emailDesigner.panel.button.width.full')}
        </ToggleButton>
        <ToggleButton value="AUTO">
          {t('emailDesigner.panel.button.width.auto')}
        </ToggleButton>
      </RadioGroupInput>
      <RadioGroupInput
        label={t('emailDesigner.panel.button.size')}
        defaultValue={size}
        onChange={size =>
          updateData({ ...data, props: { ...data.props, size } })
        }
      >
        <ToggleButton value="x-small">Xs</ToggleButton>
        <ToggleButton value="small">Sm</ToggleButton>
        <ToggleButton value="medium">Md</ToggleButton>
        <ToggleButton value="large">Lg</ToggleButton>
      </RadioGroupInput>
      <RadioGroupInput
        label={t('emailDesigner.panel.button.style')}
        defaultValue={buttonStyle}
        onChange={buttonStyle =>
          updateData({ ...data, props: { ...data.props, buttonStyle } })
        }
      >
        <ToggleButton value="rectangle">
          {t('emailDesigner.panel.button.style.rectangle')}
        </ToggleButton>
        <ToggleButton value="rounded">
          {t('emailDesigner.panel.button.style.rounded')}{' '}
        </ToggleButton>
        <ToggleButton value="pill">
          {t('emailDesigner.panel.button.style.pill')}
        </ToggleButton>
      </RadioGroupInput>
      <ColorInput
        label={t('emailDesigner.panel.button.style.textColor')}
        defaultValue={buttonTextColor}
        onChange={buttonTextColor =>
          updateData({ ...data, props: { ...data.props, buttonTextColor } })
        }
      />
      <ColorInput
        label={t('emailDesigner.panel.button.style.buttonColor')}
        defaultValue={buttonBackgroundColor}
        onChange={buttonBackgroundColor =>
          updateData({
            ...data,
            props: { ...data.props, buttonBackgroundColor },
          })
        }
      />
      <MultiStylePropertyPanel
        names={[
          'backgroundColor',
          'fontFamily',
          'fontSize',
          'fontWeight',
          'textAlign',
          'padding',
        ]}
        value={data.style}
        onChange={style => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
