import { DEFAULT_PAGE_SIZE } from 'constants/common';

export const isEmpty = (value: any) => {
  if (value == null) {
    return true;
  }
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false;
};

export const isString = (value: any): boolean => {
  return typeof value === 'string' || value instanceof String;
};

export const isTitleString = (title: any) => {
  return isString(title) || title?.type?.name === 'Trans';
};

export const getPageNumberAfterLoadMore = (
  items: any[],
  pageSize: number = DEFAULT_PAGE_SIZE,
) => Math.ceil(items.length / pageSize) + 1;

export const getAvatarName = (name: string) => {
  return name.includes(' ')
    ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase()
    : name.slice(0, 2).toUpperCase();
};

export const readFile = (
  file: File | null,
  type: 'text' | 'dataURL' | 'arrayBuffer',
) => {
  return new Promise<string | ArrayBuffer>(r => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', e => {
      if (e && e.target && e.target.result && file !== null) {
        r(e.target.result);
      }
    });
    if (file !== null) {
      if (type === 'text') {
        fileReader.readAsText(file);
      } else if (type === 'dataURL') {
        fileReader.readAsDataURL(file);
      } else if (type === 'arrayBuffer') {
        fileReader.readAsArrayBuffer(file);
      }
    }
  });
};

export const removeNullFields = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => removeNullFields(item));
  }

  if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, value]) => value !== null)
        .map(([key, value]) => [key, removeNullFields(value)]),
    );
  }

  return obj;
};
