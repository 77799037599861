import React, { useState } from 'react';

import { FormControlLabel, Switch } from 'components';

type Props = {
  label: string;
  defaultValue: boolean;
  onChange: (value: boolean) => void;
};

export const BooleanInput: React.FC<Props> = ({
  label,
  defaultValue,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <FormControlLabel
      label={label}
      control={
        <Switch
          checked={value}
          onChange={(_, checked: boolean) => {
            setValue(checked);
            onChange(checked);
          }}
        />
      }
    />
  );
};
