import React from 'react';

import { TStyle } from '../../../DocumentViewer/Wrappers/TStyle';
import { SingleStylePropertyPanel } from './SingleStylePropertyPanel';

type Props = {
  names: (keyof TStyle)[];
  value: TStyle | undefined | null;
  onChange: (style: TStyle) => void;
};

export const MultiStylePropertyPanel: React.FC<Props> = ({
  names,
  value,
  onChange,
}) => (
  <>
    {names.map(name => (
      <SingleStylePropertyPanel
        key={name}
        name={name}
        value={value || {}}
        onChange={onChange}
      />
    ))}
  </>
);
