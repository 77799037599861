import React, { ChangeEvent, useState } from 'react';

import { FileUploadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useNotifications } from 'hooks';

import { useTranslation } from 'third-party';

import { useSettings } from 'modules/setting';

interface ImportJsonDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (data: string) => void;
}

const ImportJsonDialog: React.FC<ImportJsonDialogProps> = ({
  open,
  onClose,
  onImport,
}) => {
  const { t } = useTranslation();
  const { error } = useNotifications();
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.name.endsWith('.json')) {
      error(t('emailDesigner.error.invalidFile'));
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      try {
        const content = e.target?.result as string;
        JSON.parse(content);
        onImport(content);
        onClose();
      } catch {
        error(t('emailDesigner.error.invalidFile'));
      }
    };
    reader.readAsText(file);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('emailDesigner.import.selectFileTitle')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            py: 2,
          }}
        >
          <Button
            variant="outlined"
            component="label"
            startIcon={<FileUploadOutlined />}
          >
            {t('emailDesigner.import.selectFile')}
            <input
              type="file"
              hidden
              accept=".json"
              onChange={handleFileUpload}
            />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.form.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export const ImportTemplate = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { error, success } = useNotifications();
  const { setEmailTemplate } = useSettings();
  const handleImport = (content: string) => {
    try {
      setEmailTemplate(JSON.parse(content));
      success(t('emailDesigner.import.senseful'));
    } catch {
      error(t('emailDesigner.error.invalidFile'));
    }
  };

  return (
    <>
      <Tooltip title={t('emailDesigner.actions.importTemplate')}>
        <IconButton onClick={() => setOpen(true)}>
          <FileUploadOutlined fontSize="small" />
        </IconButton>
      </Tooltip>

      <ImportJsonDialog
        open={open}
        onClose={() => setOpen(false)}
        onImport={handleImport}
      />
    </>
  );
};
