import React from 'react';

import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { useSelector, useTranslation } from 'third-party';

import { settingSelectors } from 'selectors';

export const ExportTemplate = () => {
  const document = useSelector(settingSelectors.emailTemplate);
  const { t } = useTranslation();

  const content = `data:text/plain,${encodeURIComponent(
    JSON.stringify(document, null, '  '),
  )}`;

  return (
    <Tooltip title={t('emailDesigner.actions.exportTemplate')}>
      <IconButton href={content} download="emailTemplate.json">
        <FileDownloadOutlined fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
