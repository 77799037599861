import React from 'react';

import { ContainerProps } from '../../DocumentViewer/ContainerEditor';
import { BaseSidebarPanel, MultiStylePropertyPanel } from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: ContainerProps;
  setData: (data: ContainerProps) => void;
};

export const ContainerSidebarPanel: React.FC<Props> = ({ data, setData }) => {
  const { t } = useTranslation();
  return (
    <BaseSidebarPanel title={t('emailDesigner.panel.containerBlock')}>
      <MultiStylePropertyPanel
        names={['backgroundColor', 'borderColor', 'borderRadius', 'padding']}
        value={data.style}
        onChange={style => setData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
