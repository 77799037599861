import React from 'react';

import { EditOutlined, PreviewOutlined } from '@mui/icons-material';
import { Tab, Tabs, Tooltip } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

export default function MainTabsGroup() {
  const selectedMainTab = useSelector(settingSelectors.selectedMainTab);
  const { setSelectedMainTab } = useSettings();
  const { t } = useTranslation();

  const handleChange = (_: unknown, it: unknown) => {
    switch (it) {
      case 'preview':
      case 'editor':
        setSelectedMainTab(it);
        return;
      default:
        setSelectedMainTab('editor');
    }
  };

  return (
    <Tabs value={selectedMainTab} onChange={handleChange}>
      <Tab
        value="editor"
        label={
          <Tooltip title={t('emailDesigner.tabs.edit')}>
            <EditOutlined fontSize="small" />
          </Tooltip>
        }
      />
      <Tab
        value="preview"
        label={
          <Tooltip title={t('emailDesigner.tabs.preview')}>
            <PreviewOutlined fontSize="small" />
          </Tooltip>
        }
      />
    </Tabs>
  );
}
