import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import {
  AddOutlined,
  ChangeCircleOutlined,
  FileDownloadOutlined,
  PreviewOutlined,
  SaveOutlined,
  UploadFileOutlined,
} from '@mui/icons-material';
import { Template, cloneDeep, getInputFromTemplate } from '@pdfme/common';
import { generate } from '@pdfme/generator';
import {
  barcodes,
  checkbox,
  dateTime,
  ellipse,
  image,
  line,
  radioGroup,
  rectangle,
  select,
  table,
  text,
  time,
} from '@pdfme/schemas';
import { Designer } from '@pdfme/ui';
import { Button, IconButton, Loader, MenuItem, Select } from 'components';
import { useNotifications } from 'hooks';

import { getSampleTemplate } from './sampleData';

import { useParams, useTheme, useTranslation } from 'third-party';

import { useSettings } from 'modules/setting';

import { readFile, removeNullFields } from 'utils/helper';

const headerHeight = 20;
const controllerHeight = 60;
const defaultTemplate = getSampleTemplate();

const plugins = {
  text,
  image,
  qrcode: barcodes.qrcode,
  line,
  table,
  rectangle,
  ellipse,
  dateTime,
  time,
  select,
  checkbox,
  radioGroup,
};

export const CheckDesigner = () => {
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const theme = useTheme();

  const [template, setTemplate] = useState<Template>(defaultTemplate);
  const [, setSmallDisplay] = useState(true);
  const [prevDesignerRef, setPrevDesignerRef] = useState<Designer | null>(null);
  const { error, success } = useNotifications();
  const { t } = useTranslation();

  const ref = useRef<any>(null);
  const { bankId } = useParams();

  const { saveCheckTemplate, loadCheckTemplate } = useSettings();

  useEffect(() => {
    if (bankId) {
      loadCheckTemplate(bankId).then((it: any) => {
        setSmallDisplay(false);
        if (it.payload == null) {
          setTemplate(defaultTemplate);
          return;
        }

        const cleanPayload = removeNullFields(it.payload);

        setTemplate(cleanPayload);
        designer.current?.updateTemplate(cleanPayload);
      });
    }
  }, [bankId, loadCheckTemplate]);

  const buildDesigner = () => {
    if (designerRef.current && template) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
        plugins: plugins,
        options: {
          lang: 'en',
          theme: {
            token: {
              colorPrimary: theme.custom.palette.primary300,
              colorPrimaryHover: theme.custom.palette.primary800,
            },
          },
        },
      });
      designer.current.onSaveTemplate(downloadTemplate);
      designer.current.onChangeTemplate(setTemplate);
    }
  };

  const changeBasePdf = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!designer.current || !file) {
      return;
    }

    readFile(file, 'dataURL').then(basePdf => {
      designer?.current?.updateTemplate(
        Object.assign(cloneDeep(template), { basePdf }),
      );
    });
  };

  const loadTemplate = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!designer.current || !file) {
      return;
    }

    readFile(file, 'text').then(jsonStr => {
      const template: Template = JSON.parse(jsonStr as string);
      try {
        designer.current?.updateTemplate(template);
      } catch {
        error('unable to load file');
      }
    });
  };

  const downloadTemplate = () => {
    const json = designer.current?.getTemplate();
    const title = 'template';
    if (typeof window !== 'undefined') {
      const blob = new Blob([JSON.stringify(json)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${title}.json`;
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  const generatePdf = async () => {
    const inputs = getInputFromTemplate(template);
    const pdf = await generate({
      template,
      plugins: plugins,
      inputs,
    });
    const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
    window.open(URL.createObjectURL(blob));
  };

  const handleCreateElement = () => {
    if (!ref.current) {
      return;
    }
    const element = baseElements.find(it => it.name == ref.current.value);
    if (element) {
      setTemplate(its => {
        const newTemplate = {
          ...its,
          schemas: its.schemas.map((schemaArray, index) =>
            index === 0 ? [...schemaArray, element] : schemaArray,
          ),
        };
        designer.current?.updateTemplate(newTemplate);
        return newTemplate;
      });
    }
  };

  const handleSave = async () => {
    if (!bankId) {
      return;
    }
    await saveCheckTemplate(bankId, template);
    success(t('checkDesigner.actions.saveComplete'));
  };

  if (template == null) {
    return <Loader />;
  }

  //@ts-ignore
  if (designerRef.current && designerRef != prevDesignerRef) {
    if (prevDesignerRef && designer.current) {
      designer.current.destroy();
    }

    buildDesigner();
    //@ts-ignore
    setPrevDesignerRef(designerRef);
  }

  const baseElements = defaultTemplate?.schemas
    .flatMap(its => its)
    .filter(it => {
      const exists = template.schemas.some(t =>
        t.some(e => e.name === it.name),
      );
      return !exists;
    });

  return (
    <>
      <div
        style={{
          height: controllerHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 1rem',
        }}
      >
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button
            variant="outlined"
            size="small"
            component="label"
            startIcon={<ChangeCircleOutlined fontSize="small" />}
          >
            {t('checkDesigner.actions.changePDF')}
            <input type="file" hidden accept=".pdf" onChange={changeBasePdf} />
          </Button>

          <Button
            variant="outlined"
            component="label"
            size="small"
            startIcon={<UploadFileOutlined fontSize="small" />}
          >
            {t('checkDesigner.actions.loadTemplate')}
            <input type="file" hidden accept=".json" onChange={loadTemplate} />
          </Button>

          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={downloadTemplate}
            startIcon={<FileDownloadOutlined fontSize="small" />}
          >
            {t('checkDesigner.actions.downloadTemplate')}
          </Button>

          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<PreviewOutlined fontSize="small" />}
            onClick={generatePdf}
          >
            {t('checkDesigner.actions.previewPDF')}
          </Button>
        </div>
        <div>
          <Select
            size="small"
            inputRef={ref}
            variant="standard"
            fullWidth
            labelId="demo-select-small-label"
            id="demo-select-small"
            disabled={!baseElements.length}
            displayEmpty
            sx={{
              width: '10rem',
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
            }}
          >
            {baseElements.map(it => (
              <MenuItem key={it.name} value={it.name}>
                {it.name}
              </MenuItem>
            ))}
          </Select>

          <IconButton
            onClick={handleCreateElement}
            disabled={!baseElements.length}
          >
            <AddOutlined fontSize="small" />
          </IconButton>

          <IconButton onClick={handleSave}>
            <SaveOutlined fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div
        ref={designerRef}
        style={{
          width: '100%',
          height: `calc(100vh - ${headerHeight + controllerHeight}px)`,
        }}
      />
    </>
  );
};
