import React from 'react';

import { Avatar, AvatarProps } from '@usewaypoint/block-avatar';
import { Button, ButtonProps } from '@usewaypoint/block-button';
import { Divider, DividerProps } from '@usewaypoint/block-divider';
import { Heading, HeadingProps } from '@usewaypoint/block-heading';
import { Html, HtmlProps } from '@usewaypoint/block-html';
import { Image, ImageProps } from '@usewaypoint/block-image';
import { Spacer, SpacerProps } from '@usewaypoint/block-spacer';
import { Text, TextProps } from '@usewaypoint/block-text';

import ColumnsContainerEditor from './ColumnsContainerEditor';
import ContainerEditor from './ContainerEditor';
import EmailLayoutEditor from './EmailLayoutEditor';
import { EditorBlockWrapper } from './Wrappers/EditorBlockWrapper';

export const renderers: { [key: string]: (props: any) => JSX.Element } = {
  ['Avatar']: (props: AvatarProps) => (
    <EditorBlockWrapper>
      <Avatar {...props} />
    </EditorBlockWrapper>
  ),

  ['Button']: (props: ButtonProps) => (
    <EditorBlockWrapper>
      <Button {...props} />
    </EditorBlockWrapper>
  ),

  ['Container']: props => (
    <EditorBlockWrapper>
      <ContainerEditor {...props} />
    </EditorBlockWrapper>
  ),
  ['ColumnsContainer']: props => (
    <EditorBlockWrapper>
      <ColumnsContainerEditor {...props} />
    </EditorBlockWrapper>
  ),
  ['Heading']: (props: HeadingProps) => (
    <EditorBlockWrapper>
      <Heading {...props} />
    </EditorBlockWrapper>
  ),
  ['Html']: (props: HtmlProps) => (
    <EditorBlockWrapper>
      <Html {...props} />
    </EditorBlockWrapper>
  ),

  ['Image']: (data: ImageProps) => {
    const props = {
      ...data,
      props: {
        ...data.props,
        url:
          data.props?.url ??
          'https://placehold.co/600x400@2x/F8F8F8/CCC?text=Your%20image',
      },
    };
    return (
      <EditorBlockWrapper>
        <Image {...props} />
      </EditorBlockWrapper>
    );
  },
  ['Text']: (props: TextProps) => (
    <EditorBlockWrapper>
      <Text {...props} />
    </EditorBlockWrapper>
  ),

  ['EmailLayout']: props => <EmailLayoutEditor {...props} />,

  ['Spacer']: (props: SpacerProps) => (
    <EditorBlockWrapper>
      <Spacer {...props} />
    </EditorBlockWrapper>
  ),

  ['Divider']: (props: DividerProps) => (
    <EditorBlockWrapper>
      <Divider {...props} />
    </EditorBlockWrapper>
  ),
};

export type TEditorBlock = {
  [K in keyof typeof renderers]: Parameters<(typeof renderers)[K]>[0];
};

export type TEditorConfiguration = {
  [K in keyof typeof renderers]: TEditorBlock[K];
};
