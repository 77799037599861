import React from 'react';

import { Box } from 'components';

import { Navigation } from '../Navigation/Navigation';
import { UserMenu } from '../Navigation/UserMenu';

import { RouterOutlet, styled, useSelector } from 'third-party';

import { appSelectors } from 'selectors';

const NavigationPanel = styled(Box)<{ width: string }>`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${props => props.theme.custom.palette.borderColor};
  transition: width 0.3s ease-in-out;
  width: ${props => props.width};
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex-grow: 1;
  margin: 1rem;
`;

export const MainView = () => {
  const isNavigationCollapsed = useSelector(appSelectors.isNavigationCollapsed);

  return (
    <>
      <NavigationPanel width={isNavigationCollapsed ? '5.5rem' : '14rem'}>
        <Navigation />
        <UserMenu />
      </NavigationPanel>
      <ContentWrapper>
        <RouterOutlet />
      </ContentWrapper>
    </>
  );
};
