import { State } from 'modules';

import { APIUserOrganization } from 'types/api';

export const user = (state: State) => state.app?.user;
export const selectedOrganization = (state: State) =>
  state.app?.user?.userOrganizations.find(it => it.isSelected);

export const userId = (state: State) => state.app?.user?.id;

export const userType = (state: State) => state.app?.user?.userType;

export const organizationId = (state: State) =>
  state.app?.user?.userOrganizations.find(it => it.isSelected)?.organization
    .id ?? '';

export const organization = (state: State) =>
  state.app.user?.userOrganizations as APIUserOrganization[];

export const isAppLoaded = (state: State) => state.app.isAppLoaded;
export const userInfoPending = (state: State) => state.app.userInfoPending;

export const isNavigationCollapsed = (state: State) =>
  state.app.isNavigationCollapsed;
