import React from 'react';

import { RoundedCornerOutlined } from '@mui/icons-material';

import { TStyle } from '../../../DocumentViewer/Wrappers/TStyle';
import { NullableColorInput } from './ColorInput';
import { NullableFontFamily } from './FontFamily';
import { FontSizeInput } from './FontSizeInput';
import { FontWeightInput } from './FontWeightInput';
import { PaddingInput } from './PaddingInput';
import { SliderInput } from './SliderInput';
import { TextAlignInput } from './TextAlignInput';

type Props = {
  name: keyof TStyle;
  value: TStyle;
  onChange: (style: TStyle) => void;
};
export const SingleStylePropertyPanel: React.FC<Props> = ({
  name,
  value,
  onChange,
}) => {
  const defaultValue = value[name] ?? null;

  const handleChange = (v: any) => {
    onChange({ ...value, [name]: v });
  };

  switch (name) {
    case 'backgroundColor':
      return (
        <NullableColorInput
          label="Background color"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'borderColor':
      return (
        <NullableColorInput
          label="Border color"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'borderRadius':
      return (
        <SliderInput
          iconLabel={<RoundedCornerOutlined />}
          units="px"
          step={4}
          marks
          min={0}
          max={48}
          label="Border radius"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'color':
      return (
        <NullableColorInput
          label="Text color"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'fontFamily':
      return (
        <NullableFontFamily
          label="Font family"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'fontSize':
      return (
        <FontSizeInput
          label="Font size"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'fontWeight':
      return (
        <FontWeightInput
          label="Font weight"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'textAlign':
      return (
        <TextAlignInput
          label="Alignment"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
    case 'padding':
      return (
        <PaddingInput
          label="Padding"
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      );
  }
};
