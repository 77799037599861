import React from 'react';

import { AddOutlined } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';

import { useTheme } from 'third-party';

type Props = {
  onClick: () => void;
};

export const PlaceholderButton: React.FC<Props> = ({ onClick }) => {
  const theme = useTheme();
  return (
    <ButtonBase
      onClick={ev => {
        ev.stopPropagation();
        onClick();
      }}
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        height: 48,
        width: '100%',
        bgcolor: theme.custom.palette.primary300,
      }}
    >
      <AddOutlined
        sx={{
          p: 0.12,
          bgcolor: 'brand.blue',
          borderRadius: 24,
          color: 'primary.contrastText',
        }}
        fontSize="small"
      />
    </ButtonBase>
  );
};
