import React from 'react';

import { FirstPageOutlined, LastPageOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  PopupState,
  bindMenu,
  bindTrigger,
} from 'components';

import {
  styled,
  useNavigate,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { loginUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { deleteUserToken } from 'utils/auth';
import { getAvatarName } from 'utils/helper';

const StyledFilterButton = styled(Button)`
  border-radius: 10rem;
  color: ${props => props.theme.custom.palette.blackText} !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  text-transform: none !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UserMenu = () => {
  const theme = useTheme();
  const user = useSelector(appSelectors.user);
  const navigate = useNavigate();

  const { userLogOut, toggleNavigation } = useApp();
  const { t } = useTranslation();
  const isNavigationCollapsed = useSelector(appSelectors.isNavigationCollapsed);

  const onLogOutClick = async () => {
    await userLogOut();
    deleteUserToken();

    navigate(loginUrl());
  };

  return (
    <Box margin="1rem 1rem 1rem 2rem" display="flex" alignItems="center">
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <React.Fragment>
            <Avatar
              sx={{
                width: '1.6rem',
                height: '1.6rem',
                fontSize: '0.6rem',
                bgcolor: theme.custom.palette.primary800,
                cursor: 'pointer',
                textTransform: 'uppercase',
              }}
              {...bindTrigger(popupState)}
              sizes="small"
            >
              {getAvatarName(user?.firstName + ' ' + user?.lastName)}
            </Avatar>
            {!isNavigationCollapsed && (
              <StyledFilterButton variant="text" {...bindTrigger(popupState)}>
                {user?.firstName} {user?.lastName}
              </StyledFilterButton>
            )}
            <IconButton onClick={() => toggleNavigation()}>
              {!isNavigationCollapsed ? (
                <FirstPageOutlined fontSize="small" />
              ) : (
                <LastPageOutlined fontSize="small" />
              )}
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  onLogOutClick();
                }}
              >
                {t('common.logout')}
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Box>
  );
};
