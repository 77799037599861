import React from 'react';

import {
  VerticalAlignBottomOutlined,
  VerticalAlignCenterOutlined,
  VerticalAlignTopOutlined,
} from '@mui/icons-material';
import { ImageProps } from '@usewaypoint/block-image';
import { Stack, ToggleButton } from 'components';

import {
  BaseSidebarPanel,
  MultiStylePropertyPanel,
  RadioGroupInput,
  TextDimensionInput,
  TextInput,
} from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: ImageProps;
  setData: (v: ImageProps) => void;
};

export const ImageSidebarPanel: React.FC<Props> = ({ data, setData }) => {
  const { t } = useTranslation();
  const updateData = (data: ImageProps) => {
    setData(data);
  };

  return (
    <BaseSidebarPanel title="Image block">
      <TextInput
        label={t('emailDesigner.panel.image.sourceUrl')}
        defaultValue={data.props?.url ?? ''}
        onChange={v => {
          const url = v.trim().length === 0 ? null : v.trim();
          updateData({ ...data, props: { ...data.props, url } });
        }}
      />

      <TextInput
        label={t('emailDesigner.panel.image.altText')}
        defaultValue={data.props?.alt ?? ''}
        onChange={alt => updateData({ ...data, props: { ...data.props, alt } })}
      />
      <TextInput
        label={t('emailDesigner.panel.image.clickUrl')}
        defaultValue={data.props?.linkHref ?? ''}
        onChange={v => {
          const linkHref = v.trim().length === 0 ? null : v.trim();
          updateData({ ...data, props: { ...data.props, linkHref } });
        }}
      />
      <Stack direction="row" spacing={2}>
        <TextDimensionInput
          label={t('emailDesigner.panel.image.width')}
          defaultValue={data.props?.width}
          onChange={width =>
            updateData({ ...data, props: { ...data.props, width } })
          }
        />
        <TextDimensionInput
          label={t('emailDesigner.panel.image.height')}
          defaultValue={data.props?.height}
          onChange={height =>
            updateData({ ...data, props: { ...data.props, height } })
          }
        />
      </Stack>

      <RadioGroupInput
        label={t('emailDesigner.panel.image.alignment')}
        defaultValue={data.props?.contentAlignment ?? 'middle'}
        onChange={contentAlignment =>
          updateData({
            ...data,
            props: {
              ...data.props,
              contentAlignment: contentAlignment as 'top' | 'bottom' | 'middle',
            },
          })
        }
      >
        <ToggleButton value="top">
          <VerticalAlignTopOutlined fontSize="small" />
        </ToggleButton>
        <ToggleButton value="middle">
          <VerticalAlignCenterOutlined fontSize="small" />
        </ToggleButton>
        <ToggleButton value="bottom">
          <VerticalAlignBottomOutlined fontSize="small" />
        </ToggleButton>
      </RadioGroupInput>

      <MultiStylePropertyPanel
        names={['backgroundColor', 'textAlign', 'padding']}
        value={data.style}
        onChange={style => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
