import { useMemo } from 'react';

import { bindActionCreators, useDispatch } from 'third-party';

import {
  userAuthEndpoint,
  userEndpoint,
  userInfoEndpoint,
  userPasswordResetLinkEndpoint,
  userRefreshTokenEndpoint,
  userResetPasswordEndpoint,
  validateUserResetTokenPasswordEndpoint,
} from 'constants/endpoints';

import { saveUserToken } from 'utils/auth';
import { httpMethod, simplifyBuilder } from 'utils/sra';

import { APIUser, APIUserRegister, APIUserResetPasswordModel } from 'types/api';

export type AppState = {
  user: APIUser | null;
  userInfoPending: boolean;
  isAppLoaded: boolean;
  isNavigationCollapsed: boolean;
  deleteOrganizationPending: boolean;
};

export const appState: AppState = {
  user: null,
  userInfoPending: false,
  isAppLoaded: false,
  isNavigationCollapsed: false,
  deleteOrganizationPending: false,
};

const builder = simplifyBuilder(appState, {});

export const setAppIsLoaded = builder.createReduxAction(
  (isAppLoaded: boolean) => ({
    name: 'setUser',
    updater: () => ({
      isAppLoaded: isAppLoaded,
    }),
  }),
);

export const setUser = builder.createReduxAction(
  (user: APIUser | undefined) => ({
    name: 'setUser',
    updater: () => ({
      user: user,
    }),
  }),
);

const toggleNavigation = builder.createReduxAction(() => ({
  name: 'setUser',
  updater: state => ({
    isNavigationCollapsed: !state.isNavigationCollapsed,
  }),
}));

export const userLogOut = builder.createReduxAction(() => ({
  name: 'userLogOut',
  updater: () => ({
    user: null,
  }),
}));

const userLogIn = builder.createServerAction(
  (email: string, password: string) => ({
    name: 'userLoginIn',
    url: userAuthEndpoint(),
    method: httpMethod.post,
    body: { email, password },
    onRequest: () => ({
      userLoginInErrorText: '',
    }),
    onSuccess: (state: AppState, payload: string) => {
      saveUserToken(payload);
    },
  }),
);

const userSendPasswordResetLink = builder.createServerAction(
  (email: string) => ({
    name: 'userSendPasswordResetLink',
    url: userPasswordResetLinkEndpoint(email),
    method: httpMethod.put,
  }),
);

const userResetPassword = builder.createServerAction(
  (model: APIUserResetPasswordModel) => ({
    name: 'userResetPassword',
    url: userResetPasswordEndpoint(),
    method: httpMethod.put,
    body: model,
    onRequest: () => ({
      userResetErrorText: '',
    }),
  }),
);

const validateUserResetPasswordToken = builder.createServerAction(
  (email: string, token: string) => ({
    name: 'validateUserResetPasswordToken',
    url: validateUserResetTokenPasswordEndpoint(email, token),
    method: httpMethod.get,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const userInfo = builder.createServerAction(() => ({
  name: 'userInfo',
  url: userInfoEndpoint(),
  method: httpMethod.get,
  onSuccess: (state: AppState, payload: APIUser) => {
    return {
      user: {
        ...payload,
        organizations: payload.userOrganizations.map(it => ({
          ...it,
          iseSelected: false,
        })),
      },
    };
  },
}));

const refreshTokens = builder.createServerAction(() => ({
  name: 'refreshTokens',
  url: userRefreshTokenEndpoint(),
  method: httpMethod.post,
  onSuccess: (state: AppState, payload: string) => {
    saveUserToken(payload);
  },
}));

const userRegister = builder.createServerAction((model: APIUserRegister) => ({
  name: 'userRegister',
  url: userEndpoint(),
  method: httpMethod.put,
  body: model,
  onRequest: () => ({
    userLoginInErrorText: '',
  }),
  onSuccess: (state: AppState, payload: string) => {
    saveUserToken(payload);
  },
}));

export const selectOrganization = builder.createReduxAction(
  (organizationId: string | undefined) => ({
    name: 'selectOrganization',
    updater: (state: AppState) => ({
      ...state,
      user: state.user
        ? {
            ...state.user,
            userOrganizations: state.user.userOrganizations?.map(it => ({
              ...it,
              isSelected: it.organization.id === organizationId,
            })),
          }
        : null,
    }),
  }),
);

export const useApp = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          refreshTokens,
          selectOrganization,
          userRegister,
          userSendPasswordResetLink,
          validateUserResetPasswordToken,
          userResetPassword,
          setUser,
          userInfo,
          toggleNavigation,
          setAppIsLoaded,
          userLogOut,
          userLogIn,
        },
        dispatch,
      ),
    [dispatch],
  );
};

export const appReducer = builder.getReducers();
