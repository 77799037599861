import React from 'react';

import { AspectRatioOutlined } from '@mui/icons-material';
import { AvatarProps, AvatarPropsDefaults } from '@usewaypoint/block-avatar';
import { ToggleButton } from 'components';

import {
  BaseSidebarPanel,
  MultiStylePropertyPanel,
  RadioGroupInput,
  SliderInput,
  TextInput,
} from './inputs';

type Props = {
  data: AvatarProps;
  setData: (data: AvatarProps) => void;
};

export const AvatarSidebarPanel: React.FC<Props> = ({ data, setData }) => (
  <BaseSidebarPanel title="Avatar block">
    <SliderInput
      label="Size"
      iconLabel={<AspectRatioOutlined sx={{ color: 'text.secondary' }} />}
      units="px"
      step={3}
      min={32}
      max={256}
      defaultValue={data.props?.size ?? AvatarPropsDefaults.size}
      onChange={size => {
        setData({ ...data, props: { ...data.props, size } });
      }}
    />
    <RadioGroupInput
      label="Shape"
      defaultValue={data.props?.shape ?? AvatarPropsDefaults.shape}
      onChange={shape => {
        setData({
          ...data,
          props: {
            ...data.props,
            shape: shape as 'circle' | 'square' | 'rounded',
          },
        });
      }}
    >
      <ToggleButton value="circle">Circle</ToggleButton>
      <ToggleButton value="square">Square</ToggleButton>
      <ToggleButton value="rounded">Rounded</ToggleButton>
    </RadioGroupInput>
    <TextInput
      label="Image URL"
      defaultValue={data.props?.imageUrl ?? AvatarPropsDefaults.imageUrl}
      onChange={imageUrl => {
        setData({ ...data, props: { ...data.props, imageUrl } });
      }}
    />
    <TextInput
      label="Alt text"
      defaultValue={data.props?.alt ?? AvatarPropsDefaults.alt}
      onChange={alt => {
        setData({ ...data, props: { ...data.props, alt } });
      }}
    />

    <MultiStylePropertyPanel
      names={['textAlign', 'padding']}
      value={data.style}
      onChange={style => setData({ ...data, style })}
    />
  </BaseSidebarPanel>
);
