import React from 'react';

import { TextProps } from '@usewaypoint/block-text';

import {
  BaseSidebarPanel,
  BooleanInput,
  MultiStylePropertyPanel,
  TextInput,
} from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: TextProps;
  setData: (data: TextProps) => void;
};

export const TextSidebarPanel: React.FC<Props> = ({ data, setData }) => {
  const { t } = useTranslation();
  return (
    <BaseSidebarPanel title="Text block">
      <TextInput
        label={t('emailDesigner.panel.text')}
        rows={5}
        defaultValue={data.props?.text ?? ''}
        onChange={text => setData({ ...data, props: { ...data.props, text } })}
      />
      <BooleanInput
        label="Markdown"
        defaultValue={data.props?.markdown ?? false}
        onChange={markdown =>
          setData({ ...data, props: { ...data.props, markdown } })
        }
      />

      <MultiStylePropertyPanel
        names={[
          'color',
          'backgroundColor',
          'fontFamily',
          'fontSize',
          'fontWeight',
          'textAlign',
          'padding',
        ]}
        value={data.style}
        onChange={style => setData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
