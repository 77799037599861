import React from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { ConfigurationPanel } from './ConfigurationPanel';
import { StylesPanel } from './StylesPanel';

import { useSelector } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

export const INSPECTOR_DRAWER_WIDTH = 320;

export const InspectorDrawer = () => {
  const selectedSidebarTab = useSelector(settingSelectors.selectedSidebarTab);
  const { setSelectedSidebarTab } = useSettings();
  const renderCurrentSidebarPanel = () => {
    switch (selectedSidebarTab) {
      case 'block-configuration':
        return <ConfigurationPanel />;
      case 'styles':
        return <StylesPanel />;
    }
  };

  return (
    <>
      <Box
        sx={{
          width: INSPECTOR_DRAWER_WIDTH,
          height: 49,
          overflowWrap: 'scroll',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Box px={2}>
          <Tabs
            value={selectedSidebarTab}
            onChange={(_, v) => setSelectedSidebarTab(v)}
          >
            <Tab value="styles" label="Styles" />
            <Tab value="block-configuration" label="Inspect" />
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          width: INSPECTOR_DRAWER_WIDTH,
          display: 'flex',
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        {renderCurrentSidebarPanel()}
      </Box>
    </>
  );
};
