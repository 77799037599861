import React from 'react';

import { HeadingProps, HeadingPropsDefaults } from '@usewaypoint/block-heading';
import { ToggleButton } from 'components';

import {
  BaseSidebarPanel,
  MultiStylePropertyPanel,
  RadioGroupInput,
  TextInput,
} from './inputs';

import { useTranslation } from 'third-party';

type HeadingSidebarPanelProps = {
  data: HeadingProps;
  setData: (v: HeadingProps) => void;
};
export const HeadingSidebarPanel: React.FC<HeadingSidebarPanelProps> = ({
  data,
  setData,
}) => {
  const { t } = useTranslation();
  return (
    <BaseSidebarPanel title={t('emailDesigner.panel.heading')}>
      <TextInput
        label="Content"
        rows={3}
        defaultValue={data.props?.text ?? HeadingPropsDefaults.text}
        onChange={text => {
          setData({ ...data, props: { ...data.props, text } });
        }}
      />
      <RadioGroupInput
        label={t('emailDesigner.panel.heading.level')}
        defaultValue={data.props?.level ?? HeadingPropsDefaults.level}
        onChange={level => {
          setData({
            ...data,
            props: { ...data.props, level: level as 'h1' | 'h2' | 'h3' },
          });
        }}
      >
        <ToggleButton value="h1">H1</ToggleButton>
        <ToggleButton value="h2">H2</ToggleButton>
        <ToggleButton value="h3">H3</ToggleButton>
      </RadioGroupInput>
      <MultiStylePropertyPanel
        names={[
          'color',
          'backgroundColor',
          'fontFamily',
          'fontWeight',
          'textAlign',
          'padding',
        ]}
        value={data.style}
        onChange={style => setData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
