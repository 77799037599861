/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum APIAccessLevel {
  User = 'User',
  SuperVisor = 'SuperVisor',
  Admin = 'Admin',
  ReadOnly = 'ReadOnly',
}

export interface APIBankModel {
  /** @format uuid */
  id: string;
  routing?: string | null;
  account?: string | null;
  accountName?: string | null;
  bankName?: string | null;
  companyName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  /** @format date-time */
  createdOn?: string;
}

export interface APIBankModelSearchResult {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  result?: APIBankModel[] | null;
}

export interface APIBankRequestModel {
  routing?: string | null;
  account?: string | null;
  accountName?: string | null;
  bankName?: string | null;
  companyName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface APIBankSearchFilter {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  searchSortOrders?: APISearchSortOrder[] | null;
  id?: string | null;
  routing?: string | null;
  account?: string | null;
  accountName?: string | null;
  bankName?: string | null;
  companyName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface APIGuidTypeaheadModel {
  /** @format uuid */
  id?: string;
  name?: string | null;
}

export interface APIGuidTypeaheadRequestModel {
  query?: string | null;
  /** @format int32 */
  count?: number;
  excludedIds?: string[] | null;
  includedIds?: string[] | null;
}

export interface APIOrganizationModel {
  /** @format uuid */
  id: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /** @maxLength 255 */
  description?: string | null;
}

export interface APIOrganizationModelSearchResult {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  result?: APIOrganizationModel[] | null;
}

export interface APIOrganizationSearchFilter {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  searchSortOrders?: APISearchSortOrder[] | null;
  organizationIds?: string[] | null;
  /** @format uuid */
  id?: string | null;
  name?: string | null;
  description?: string | null;
}

export interface APIPayeeModel {
  /** @format uuid */
  id: string;
  user?: APIUserModel;
  nameOnCheck?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  /** @format date-time */
  createdOn?: string;
}

export interface APIPayeeModelSearchResult {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  result?: APIPayeeModel[] | null;
}

export interface APIPayeeRequestModel {
  /** @format uuid */
  userId?: string;
  nameOnCheck?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface APIPayeeSearchFilter {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  searchSortOrders?: APISearchSortOrder[] | null;
  nameOnCheck?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  organizationIds?: string[] | null;
}

export interface APIPaymentModel {
  /** @format uuid */
  id: string;
  bank?: APIBankModel;
  /** @format double */
  amount?: number;
  /** @format date-time */
  checkDate?: string;
  checkNumber?: string | null;
  note?: string | null;
  memo?: string | null;
  name?: string | null;
  company?: string | null;
  referenceId?: string | null;
  payee?: APIPayeeModel;
  eCheck?: boolean;
  status?: APIPaymentStatus;
  kId?: string | null;
  /** @format uuid */
  createdById?: string;
  /** @format date-time */
  createdOn?: string;
  /** @format uuid */
  updatedById?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface APIPaymentModelSearchResult {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  result?: APIPaymentModel[] | null;
}

export interface APIPaymentRequestModel {
  /** @format double */
  amount?: number;
  /** @format date-time */
  checkDate?: string;
  checkNumber?: string | null;
  note?: string | null;
  memo?: string | null;
  name?: string | null;
  company?: string | null;
  referenceId?: string | null;
  eCheck?: boolean;
  /** @format uuid */
  bankId?: string;
  kId?: string | null;
  status?: APIPaymentStatus;
  /** @format uuid */
  payeeId?: string | null;
  nameOnCheck?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface APIPaymentSearchFilter {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  searchSortOrders?: APISearchSortOrder[] | null;
  organizationIds?: string[] | null;
  id?: string | null;
  /** @format double */
  amount?: number | null;
  checkNumber?: string | null;
  note?: string | null;
  memo?: string | null;
  name?: string | null;
  company?: string | null;
  nameOnCheck?: string | null;
  referenceId?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  eCheck?: boolean | null;
  status?: APIPaymentStatus;
  kId?: string | null;
}

export enum APIPaymentStatus {
  Draft = 'Draft',
  Requested = 'Requested',
  Created = 'Created',
  Void = 'Void',
  TryToPrint = 'TryToPrint',
  CouldNotPrint = 'CouldNotPrint',
  Printed = 'Printed',
  Emailed = 'Emailed',
  Mailed = 'Mailed',
  Expired = 'Expired',
  Cleared = 'Cleared',
}

export interface APISearchSortOrder {
  /** @default "Id" */
  sortField?: string | null;
  sortOrder?: APISortOrder;
}

export interface APISettingModel {
  name: APISettingName;
  /** @minLength 1 */
  value: string;
}

export enum APISettingName {
  NewECheckReceiveEmail = 'NewECheckReceiveEmail',
  AddedToOrganizationEmail = 'AddedToOrganizationEmail',
}

export enum APISortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface APIUserAuthModel {
  email?: string | null;
  password?: string | null;
}

export interface APIUserModel {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  email: string;
  userType?: APIUserType;
  accessLevel?: APIAccessLevel;
  userOrganizations: APIUserOrganizationModel[];
}

export interface APIUserModelSearchResult {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  result?: APIUserModel[] | null;
}

export interface APIUserOrganizationModel {
  organization: APIOrganizationModel;
  userType?: APIUserType;
  accessLevel?: APIAccessLevel;
}

export interface APIUserRegisterModel {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  password?: string | null;
}

export interface APIUserResetPasswordModel {
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  password: string;
  /** @format uuid */
  resetPasswordToken: string;
}

export interface APIUserSearchFilter {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  searchSortOrders?: APISearchSortOrder[] | null;
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  userType?: APIUserType;
  userStatus?: APIUserStatus;
  accessLevel?: APIAccessLevel;
  organizationIds?: string[] | null;
}

export enum APIUserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
}

export enum APIUserType {
  User = 'User',
  Admin = 'Admin',
}
