import React from 'react';

import { TextField, Typography } from 'components';

type Props = {
  label: string;
  defaultValue: number | null | undefined;
  onChange: (data: number | null) => void;
};

export const TextDimensionInput: React.FC<Props> = ({
  label,
  defaultValue,
  onChange,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const value = parseInt(ev.target.value);
    onChange(isNaN(value) ? null : value);
  };
  return (
    <TextField
      fullWidth
      onChange={handleChange}
      defaultValue={defaultValue}
      label={label}
      variant="standard"
      placeholder="auto"
      size="small"
      InputProps={{
        endAdornment: (
          <Typography variant="body2" color="text.secondary">
            px
          </Typography>
        ),
      }}
    />
  );
};
