import React from 'react';

import { Box, Typography } from '@mui/material';

import { TEditorBlock } from '../DocumentViewer/Renders';
import { AvatarSidebarPanel } from './Panels/AvatarSidebarPanel';
import { ButtonSidebarPanel } from './Panels/ButtonSidebarPanel';
import { ColumnsContainerSidebarPanel } from './Panels/ColumnsContainerSidebarPanel';
import { ContainerSidebarPanel } from './Panels/ContainerSidebarPanel';
import { DividerSidebarPanel } from './Panels/DividerSidebarPanel';
import { EmailLayoutSidebarFields } from './Panels/EmailLayoutSidebarPanel';
import { HeadingSidebarPanel } from './Panels/HeadingSidebarPanel';
import { HtmlSidebarPanel } from './Panels/HtmlSidebarPanel';
import { ImageSidebarPanel } from './Panels/ImageSidebarPanel';
import { SpacerSidebarPanel } from './Panels/SpacerSidebarPanel';
import { TextSidebarPanel } from './Panels/TextSidebarPanel';

import { useSelector } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

function renderMessage(val: string) {
  return (
    <Box sx={{ m: 3, p: 1, border: '1px dashed', borderColor: 'divider' }}>
      <Typography color="text.secondary">{val}</Typography>
    </Box>
  );
}

export const ConfigurationPanel = () => {
  const document = useSelector(settingSelectors.emailTemplate);

  const { setEmailTemplate } = useSettings();
  const selectedBlockId = useSelector(settingSelectors.selectedTemplateBlockId);

  if (!selectedBlockId) {
    return renderMessage('Click on a block to inspect.');
  }
  const block = document[selectedBlockId];
  if (!block) {
    return renderMessage(
      `Block with id ${selectedBlockId} was not found. Click on a block to reset.`,
    );
  }

  const setBlock = (conf: TEditorBlock) =>
    setEmailTemplate({ [selectedBlockId]: conf });

  const { data, type } = block;
  switch (type) {
    case 'Avatar':
      return (
        <AvatarSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Button':
      return (
        <ButtonSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'ColumnsContainer':
      return (
        <ColumnsContainerSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Container':
      return (
        <ContainerSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Divider':
      return (
        <DividerSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Heading':
      return (
        <HeadingSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Html':
      return (
        <HtmlSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Image':
      return (
        <ImageSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'EmailLayout':
      return (
        <EmailLayoutSidebarFields
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Spacer':
      return (
        <SpacerSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    case 'Text':
      return (
        <TextSidebarPanel
          key={selectedBlockId}
          data={data}
          setData={data => setBlock({ type, data })}
        />
      );
    default:
      return <pre>{JSON.stringify(block, null, '  ')}</pre>;
  }
};
