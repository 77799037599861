import React from 'react';

import { SaveOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { renderToStaticMarkup } from '@usewaypoint/email-builder';
import { useNotifications } from 'hooks';

import { useSelector, useTranslation } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

export const SaveTemplate = () => {
  const document = useSelector(settingSelectors.emailTemplate);
  const template = useSelector(settingSelectors.selectedTemplate);
  const { saveSetting } = useSettings();
  const { t } = useTranslation();
  const { success } = useNotifications();

  const handleSave = () => {
    saveSetting({
      name: template,
      value: JSON.stringify({
        json: document,
        html: renderToStaticMarkup(document, { rootBlockId: 'root' }),
      }),
    });
    success(t('emailDesigner.save.senseful'));
  };

  return (
    <Tooltip title={t('emailDesigner.actions.saveTemplate')}>
      <IconButton onClick={() => handleSave()}>
        <SaveOutlined fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
