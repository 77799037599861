import React from 'react';

import { EmailLayoutSidebarFields } from './Panels/EmailLayoutSidebarPanel';

import { useSelector } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

export const StylesPanel = () => {
  const template = useSelector(settingSelectors.emailTemplate);
  const { setEmailTemplate } = useSettings();
  const document = template.root;

  const block = document;
  if (!block) {
    return <p>Block not found</p>;
  }

  const { data, type } = block;
  if (type !== 'EmailLayout') {
    throw new Error('Expected "root" element to be of type EmailLayout');
  }

  return (
    <EmailLayoutSidebarFields
      key="root"
      data={data}
      setData={data => setEmailTemplate({ root: { type, data } })}
    />
  );
};
