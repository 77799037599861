import React from 'react';

import {
  SpaceBarOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignCenterOutlined,
  VerticalAlignTopOutlined,
} from '@mui/icons-material';
import { ToggleButton } from 'components';

import { ColumnsContainerProps } from '../../DocumentViewer/ColumnsContainerEditor';
import {
  BaseSidebarPanel,
  ColumnWidthsInput,
  MultiStylePropertyPanel,
  RadioGroupInput,
  SliderInput,
} from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: ColumnsContainerProps;
  setData: (data: ColumnsContainerProps) => void;
};

export const ColumnsContainerSidebarPanel: React.FC<Props> = ({
  data,
  setData,
}) => {
  const updateData = (data: any) => {
    setData(data);
  };

  const { t } = useTranslation();

  return (
    <BaseSidebarPanel title="Columns block">
      <RadioGroupInput
        label={t('emailDesigner.panel.columnsContainer')}
        defaultValue={data.props?.columnsCount === 2 ? '2' : '3'}
        onChange={v => {
          updateData({
            ...data,
            props: { ...data.props, columnsCount: v === '2' ? 2 : 3 },
          });
        }}
      >
        <ToggleButton value="2">2</ToggleButton>
        <ToggleButton value="3">3</ToggleButton>
      </RadioGroupInput>
      <ColumnWidthsInput
        defaultValue={data.props?.fixedWidths}
        onChange={fixedWidths => {
          updateData({ ...data, props: { ...data.props, fixedWidths } });
        }}
      />
      <SliderInput
        label={t('emailDesigner.panel.columnsContainer.columnsGap')}
        iconLabel={<SpaceBarOutlined sx={{ color: 'text.secondary' }} />}
        units="px"
        step={4}
        marks
        min={0}
        max={80}
        defaultValue={data.props?.columnsGap ?? 0}
        onChange={columnsGap =>
          updateData({ ...data, props: { ...data.props, columnsGap } })
        }
      />
      <RadioGroupInput
        label={t('emailDesigner.panel.columnsContainer.alignment')}
        defaultValue={data.props?.contentAlignment ?? 'middle'}
        onChange={contentAlignment => {
          updateData({ ...data, props: { ...data.props, contentAlignment } });
        }}
      >
        <ToggleButton value="top">
          <VerticalAlignTopOutlined fontSize="small" />
        </ToggleButton>
        <ToggleButton value="middle">
          <VerticalAlignCenterOutlined fontSize="small" />
        </ToggleButton>
        <ToggleButton value="bottom">
          <VerticalAlignBottomOutlined fontSize="small" />
        </ToggleButton>
      </RadioGroupInput>

      <MultiStylePropertyPanel
        names={['backgroundColor', 'padding']}
        value={data.style}
        onChange={style => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
