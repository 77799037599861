import React from 'react';

import { BaseColorInput } from './BaseColorInput';

type Props = {
  label: string;
  onChange: (value: string) => void;
  defaultValue: string;
};
export const ColorInput = (props: Props) => (
  <BaseColorInput {...props} nullable={false} />
);

type NullableProps = {
  label: string;
  onChange: (value: null | string) => void;
  defaultValue: null | string;
};

export const NullableColorInput = (props: NullableProps) => (
  <BaseColorInput {...props} nullable />
);
