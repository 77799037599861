import React from 'react';

import { HtmlProps } from '@usewaypoint/block-html';

import { BaseSidebarPanel, MultiStylePropertyPanel, TextInput } from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: HtmlProps;
  setData: (data: HtmlProps) => void;
};

export const HtmlSidebarPanel: React.FC<Props> = ({ data, setData }) => {
  const { t } = useTranslation();

  return (
    <BaseSidebarPanel title="Html block">
      <TextInput
        label={t('emailDesigner.panel.html')}
        rows={5}
        defaultValue={data.props?.contents ?? ''}
        onChange={contents =>
          setData({ ...data, props: { ...data.props, contents } })
        }
      />
      <MultiStylePropertyPanel
        names={[
          'color',
          'backgroundColor',
          'fontFamily',
          'fontSize',
          'textAlign',
          'padding',
        ]}
        value={data.style}
        onChange={style => setData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
