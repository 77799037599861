import React, { useState } from 'react';

import { InputLabel, Stack, ToggleButtonGroup } from '@mui/material';

type Props = {
  label: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
  defaultValue: string;
  onChange: (data: string) => void;
};

export const RadioGroupInput: React.FC<Props> = ({
  label,
  children,
  defaultValue,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <Stack alignItems="flex-start">
      <InputLabel shrink>{label}</InputLabel>
      <ToggleButtonGroup
        exclusive
        fullWidth
        value={value}
        size="small"
        onChange={(_, data: unknown) => {
          if (typeof data !== 'string') {
            throw new Error('RadioGroupInput can only receive string values');
          }
          setValue(data);
          onChange(data);
        }}
      >
        {children}
      </ToggleButtonGroup>
    </Stack>
  );
};
