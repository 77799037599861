export const APP_PATH = process.env['APP_PATH'];
export const API_PATH = process.env['API_PATH'];

export const appUrl = () => `${APP_PATH}`;
export const notFoundUrl = () => `${appUrl()}404/`;

// anonymous routes
export const userFormUrl = () => `${appUrl()}user/`;
export const loginUrl = () => `${userFormUrl()}login`;
export const registerUrl = () => `${userFormUrl()}register`;
export const forgotPasswordUrl = () => `${userFormUrl()}forgot-password`;
export const eCheckUrl = (eCheckId: string) => `${appUrl()}/echeck/${eCheckId}`;

// user routes
export const organizationListUrl = () => `${appUrl()}organization/`;
export const userPayeeListUrl = () => `${appUrl()}payees/`;
export const dashboardUrl = () => `${appUrl()}`;
export const resetPasswordUrl = () => `${appUrl()}reset-password/`;
export const resetUserPasswordUrl = (email: string, token: string) =>
  `${resetPasswordUrl()}${email}/${token}`;

// organization routes
export const organizationUrl = (organizationId: string) =>
  `${organizationListUrl()}${organizationId}/`;
export const paymentListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}payment/`;

export const organizationDashboardUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}`;

export const bankListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}bank/`;

export const bankCheckListUrl = (organizationId: string, bankId: string) =>
  `${bankListUrl(organizationId)}${bankId}/check`;

export const userPaymentListUrl = () => `${appUrl()}payments/`;

export const payeeListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}payees/`;

export const memberListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}members/`;

export const userActivityListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}activity/`;

export const settingsUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}settings/`;
