import React from 'react';

import { RoundedCornerOutlined } from '@mui/icons-material';

import type { EmailLayoutProps } from '../../DocumentViewer/EmailLayoutEditor';
import {
  BaseSidebarPanel,
  ColorInput,
  NullableColorInput,
  NullableFontFamily,
  SliderInput,
} from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: EmailLayoutProps;
  setData: (data: EmailLayoutProps) => void;
};

export const EmailLayoutSidebarFields: React.FC<Props> = ({
  data,
  setData,
}) => {
  const { t } = useTranslation();
  const updateData = (data: EmailLayoutProps) => {
    setData(data);
  };

  return (
    <BaseSidebarPanel title={t('emailDesigner.panel.emailLayout')}>
      <ColorInput
        label={t('emailDesigner.panel.emailLayout.backdropColor')}
        defaultValue={data.backdropColor ?? '#F5F5F5'}
        onChange={backdropColor => updateData({ ...data, backdropColor })}
      />
      <ColorInput
        label={t('emailDesigner.panel.emailLayout.canvasColor')}
        defaultValue={data.canvasColor ?? '#FFFFFF'}
        onChange={canvasColor => updateData({ ...data, canvasColor })}
      />
      <NullableColorInput
        label={t('emailDesigner.panel.emailLayout.canvasBorderColor')}
        defaultValue={data.borderColor ?? null}
        onChange={borderColor => updateData({ ...data, borderColor })}
      />
      <SliderInput
        iconLabel={<RoundedCornerOutlined />}
        units="px"
        step={4}
        marks
        min={0}
        max={48}
        label={t('emailDesigner.panel.emailLayout.canvasBorderRadius')}
        defaultValue={data.borderRadius ?? 0}
        onChange={borderRadius => updateData({ ...data, borderRadius })}
      />
      <NullableFontFamily
        label={t('emailDesigner.panel.emailLayout.fontFamily')}
        defaultValue="MODERN_SANS"
        onChange={fontFamily =>
          updateData({
            ...data,
            fontFamily: fontFamily as EmailLayoutProps['fontFamily'],
          })
        }
      />
      <ColorInput
        label={t('emailDesigner.panel.emailLayout.textColor')}
        defaultValue={data.textColor ?? '#262626'}
        onChange={textColor => updateData({ ...data, textColor })}
      />
    </BaseSidebarPanel>
  );
};
