import React, { useCallback, useEffect, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { DialogModal, Loader, MenuItem, ModalBox, Table } from 'components';

import { BankCreateEditForm } from './BankCreateEditForm';
import { bankColumns } from './bankColumns';

import { useNavigate, useSelector, useTranslation } from 'third-party';

import { bankCheckListUrl } from 'constants/url';

import { useBank } from 'modules/bank';

import { appSelectors, bankSelectors } from 'selectors';

import { APIBank, APISortOrder } from 'types/api';

export const BanksList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columns, setColumns] = useState(bankColumns);
  const [selectedItem, setSelectedItem] = useState<APIBank | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loadBanks, loadMoreBanks, updateBankFilters, deleteBank } = useBank();

  const organizationId = useSelector(appSelectors.organizationId);
  const items = useSelector(bankSelectors.bankList);
  const banksListLoaded = useSelector(bankSelectors.banksListLoaded);
  const allBanksLoaded = useSelector(bankSelectors.allBanksLoaded);
  const bankFilters = useSelector(bankSelectors.bankFilters);
  const loadBanksPending = useSelector(bankSelectors.loadBanksPending);
  const loadMoreBanksPending = useSelector(bankSelectors.loadMoreBanksPending);

  useEffect(() => {
    if (organizationId) {
      loadBanks();
    }
  }, [organizationId, bankFilters, loadBanks]);

  const onColumnFilterChange = useCallback(
    (columnId: string, value: string) => {
      setColumns(
        columns.map(column => {
          if (column.dataId === columnId) {
            return { ...column, filter: value };
          }
          return column;
        }),
      );

      updateBankFilters({ ...bankFilters, [columnId]: value });
    },
    [bankFilters, columns, updateBankFilters],
  );

  const onFilterReset = useCallback(() => {
    updateBankFilters({});
    setColumns(it => it.map(column => ({ ...column, filter: undefined })));
  }, [updateBankFilters]);

  const onColumnVisibilityChanged = useCallback(
    (columnId: string, value: boolean) => {
      setColumns(it =>
        it.map(column => {
          if (column.dataId === columnId) {
            return { ...column, hidden: !value };
          }
          return column;
        }),
      );
    },
    [],
  );

  const rowOptionsMenu = useCallback(
    (item: APIBank, onRowMenuClose: () => void): React.ReactElement[] => [
      <MenuItem
        key={'delete'}
        onClick={() => {
          navigate(bankCheckListUrl(organizationId, item.id));
        }}
      >
        <DesignServicesOutlinedIcon
          color="primary"
          style={{ paddingRight: '0.5rem' }}
        />
        {t('bankList.actions.checkDesigner')}
      </MenuItem>,
      <MenuItem
        key={'delete'}
        onClick={() => {
          setSelectedItem(item);
          onRowMenuClose();
        }}
      >
        <DeleteOutlineOutlinedIcon
          color="primary"
          style={{ paddingRight: '0.5rem' }}
        />
        {t('table.delete')}
      </MenuItem>,
      <MenuItem
        key={'edit'}
        onClick={() => {
          setIsModalOpen(true);
          setSelectedItem(item);
          onRowMenuClose();
        }}
      >
        <EditOutlinedIcon color="primary" style={{ paddingRight: '0.5rem' }} />
        {t('table.edit')}
      </MenuItem>,
    ],
    [navigate, organizationId, t],
  );

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedItem(null);
  }, []);

  const onColumnHeaderClicked = useCallback(
    (columnDataId: string) => {
      const existingSortOrder = bankFilters.searchSortOrders?.find(
        order => order.sortField === columnDataId,
      );

      const newSortOrder =
        existingSortOrder && existingSortOrder.sortOrder === APISortOrder.DESC
          ? APISortOrder.ASC
          : APISortOrder.DESC;

      updateBankFilters({
        ...bankFilters,
        searchSortOrders: [
          {
            sortField: columnDataId,
            sortOrder: newSortOrder,
          },
        ],
      });
    },
    [bankFilters, updateBankFilters],
  );

  if (!banksListLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Table<APIBank>
        header={t('bankList.header')}
        subHeader={t('bankList.subHeader')}
        filterable
        allItemsLoaded={allBanksLoaded}
        infiniteScroll
        tableMinHeight="20rem"
        columns={columns}
        items={items || []}
        sortOrder={bankFilters.searchSortOrders?.[0]?.sortOrder}
        rowOptionsMenu={rowOptionsMenu}
        onColumnHeaderClicked={onColumnHeaderClicked}
        onColumnFilterChange={onColumnFilterChange}
        onColumnVisibilityChanged={onColumnVisibilityChanged}
        loadItemsPending={loadBanksPending}
        loadMoreItemsPending={loadMoreBanksPending}
        onLoadMoreClicked={loadMoreBanks}
        onFilterReset={onFilterReset}
        bodyRowHeight="1rem"
        headerRowHeight="0.2rem"
        itemsLoaded={banksListLoaded}
        onNewItemButtonClicked={() => setIsModalOpen(true)}
        emptyText={t('table.empty')}
        loadMoreButtonText={t('table.loadMore')}
      />
      {isModalOpen && (
        <ModalBox width="30rem" height="39rem" onClose={onModalClose}>
          <BankCreateEditForm
            onModalClose={onModalClose}
            bankId={selectedItem?.id}
          />
        </ModalBox>
      )}
      <DialogModal
        onClose={() => setSelectedItem(null)}
        open={!!selectedItem && !isModalOpen}
        description={t('payeeList.deleteConfirm')}
        handleActionClick={() => {
          if (selectedItem) {
            deleteBank(selectedItem.id);
          }
          setSelectedItem(null);
        }}
      />
    </>
  );
};
