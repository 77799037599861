import React from 'react';

import { HeightOutlined } from '@mui/icons-material';
import { DividerProps, DividerPropsDefaults } from '@usewaypoint/block-divider';

import {
  BaseSidebarPanel,
  ColorInput,
  MultiStylePropertyPanel,
  SliderInput,
} from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: DividerProps;
  setData: (data: DividerProps) => void;
};

export const DividerSidebarPanel: React.FC<Props> = ({ data, setData }) => {
  const updateData = (data: DividerProps) => {
    setData(data);
  };

  const lineColor = data.props?.lineColor ?? DividerPropsDefaults.lineColor;
  const lineHeight = data.props?.lineHeight ?? DividerPropsDefaults.lineHeight;
  const { t } = useTranslation();

  return (
    <BaseSidebarPanel title={t('emailDesigner.panel.dividerBlock')}>
      <ColorInput
        label={t('emailDesigner.panel.dividerBlock.color')}
        defaultValue={lineColor}
        onChange={lineColor =>
          updateData({ ...data, props: { ...data.props, lineColor } })
        }
      />
      <SliderInput
        label={t('emailDesigner.panel.dividerBlock.height')}
        iconLabel={<HeightOutlined sx={{ color: 'text.secondary' }} />}
        units="px"
        step={1}
        min={1}
        max={24}
        defaultValue={lineHeight}
        onChange={lineHeight =>
          updateData({ ...data, props: { ...data.props, lineHeight } })
        }
      />
      <MultiStylePropertyPanel
        names={['backgroundColor', 'padding']}
        value={data.style}
        onChange={style => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
};
