import React from 'react';

import { HistoryOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { useTranslation } from 'third-party';

import { EMPTY_EMAIL_MESSAGE, useSettings } from 'modules/setting';

export const RevertTemplate = () => {
  const { resetEmailTemplate } = useSettings();
  const { t } = useTranslation();

  const handleRevert = () => {
    resetEmailTemplate(EMPTY_EMAIL_MESSAGE);
  };

  return (
    <Tooltip title={t('emailDesigner.actions.revertTemplate')}>
      <IconButton onClick={() => handleRevert()}>
        <HistoryOutlined fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
