import React from 'react';

import { HeightOutlined } from '@mui/icons-material';
import { SpacerProps, SpacerPropsDefaults } from '@usewaypoint/block-spacer';

import { BaseSidebarPanel, SliderInput } from './inputs';

import { useTranslation } from 'third-party';

type Props = {
  data: SpacerProps;
  setData: (v: SpacerProps) => void;
};

export const SpacerSidebarPanel: React.FC<Props> = ({ data, setData }) => {
  const { t } = useTranslation();
  return (
    <BaseSidebarPanel title={t('emailDesigner.panel.spacer')}>
      <SliderInput
        label="Height"
        iconLabel={<HeightOutlined sx={{ color: 'text.secondary' }} />}
        units="px"
        step={4}
        min={4}
        max={128}
        defaultValue={data.props?.height ?? SpacerPropsDefaults.height}
        onChange={height =>
          setData({ ...data, props: { ...data.props, height } })
        }
      />
    </BaseSidebarPanel>
  );
};
