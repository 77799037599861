import React from 'react';

import {
  ColumnsContainer as BaseColumnsContainer,
  ColumnsContainerProps as BaseColumnsContainerProps,
} from '@usewaypoint/block-columns-container';

import { useCurrentBlockId } from './EditorBlock';
import {
  EditorChildrenChange,
  EditorChildrenIds,
} from './Wrappers/EditorChildrenIds';

import { useSettings } from 'modules/setting';

const EMPTY_COLUMNS = [
  { childrenIds: [] },
  { childrenIds: [] },
  { childrenIds: [] },
];

interface Column {
  childrenIds: string[];
}

export type ColumnsContainerProps = {
  style: BaseColumnsContainerProps['style'];
  props?:
    | (BaseColumnsContainerProps['props'] & {
        columns: [Column, Column, Column];
      })
    | null;
};

export default function ColumnsContainerEditor({
  style,
  props,
}: ColumnsContainerProps) {
  const currentBlockId = useCurrentBlockId();

  const { columns, ...restProps } = props ?? {};
  const columnsValue = columns ?? EMPTY_COLUMNS;
  const { setEmailTemplate, setSelectedTemplateBlockId } = useSettings();

  const updateColumn = (
    columnIndex: 0 | 1 | 2,
    { block, blockId, childrenIds }: EditorChildrenChange,
  ) => {
    const nColumns = [...columnsValue];
    nColumns[columnIndex] = { childrenIds };
    setEmailTemplate({
      [blockId]: block,
      [currentBlockId]: {
        type: 'ColumnsContainer',
        data: {
          style,
          props: {
            ...restProps,
            columns: nColumns,
          },
        },
      },
    });
    setSelectedTemplateBlockId(blockId);
  };

  return (
    <BaseColumnsContainer
      props={restProps}
      style={style}
      columns={[
        <EditorChildrenIds
          key="1"
          childrenIds={columns?.[0]?.childrenIds}
          onChange={change => updateColumn(0, change)}
        />,
        <EditorChildrenIds
          key="2"
          childrenIds={columns?.[1]?.childrenIds}
          onChange={change => updateColumn(1, change)}
        />,
        <EditorChildrenIds
          key="3"
          childrenIds={columns?.[2]?.childrenIds}
          onChange={change => updateColumn(2, change)}
        />,
      ]}
    />
  );
}
