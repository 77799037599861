import React, { CSSProperties, useState } from 'react';

import { Box } from 'components';

import { useCurrentBlockId } from '../EditorBlock';
import { TuneMenu } from './TuneMenu';

import { useSelector } from 'third-party';

import { useSettings } from 'modules/setting';

import { settingSelectors } from 'selectors';

type Props = {
  children: JSX.Element;
};

export const EditorBlockWrapper: React.FC<Props> = ({ children }) => {
  const selectedBlockId = useSelector(settingSelectors.selectedTemplateBlockId);
  const [mouseInside, setMouseInside] = useState(false);
  const blockId = useCurrentBlockId();
  const { setSelectedTemplateBlockId } = useSettings();

  let outline: CSSProperties['outline'];
  if (selectedBlockId === blockId) {
    outline = '2px solid rgba(0,121,204, 1)';
  } else if (mouseInside) {
    outline = '2px solid rgba(0,121,204, 0.3)';
  }

  const renderMenu = () => {
    if (selectedBlockId !== blockId) {
      return null;
    }
    return <TuneMenu blockId={blockId} />;
  };

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '100%',
        outlineOffset: '-1px',
        outline,
      }}
      onMouseEnter={ev => {
        setMouseInside(true);
        ev.stopPropagation();
      }}
      onMouseLeave={() => {
        setMouseInside(false);
      }}
      onClick={ev => {
        setSelectedTemplateBlockId(blockId);
        ev.stopPropagation();
        ev.preventDefault();
      }}
    >
      {renderMenu()}
      {children}
    </Box>
  );
};
