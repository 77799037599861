import React, { useState } from 'react';

import { InputLabel, Stack } from 'components';

import { RawSliderInput } from './RawSliderInput';

type Props = {
  label: string;
  iconLabel: JSX.Element;

  step?: number;
  marks?: boolean;
  units: string;
  min?: number;
  max?: number;

  defaultValue: number;
  onChange: (v: number) => void;
};

export const SliderInput: React.FC<Props> = ({
  label,
  defaultValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <Stack spacing={1} alignItems="flex-start">
      <InputLabel shrink>{label}</InputLabel>
      <RawSliderInput
        value={value}
        setValue={(value: number) => {
          setValue(value);
          onChange(value);
        }}
        {...props}
      />
    </Stack>
  );
};
